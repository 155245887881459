.accent-slate {
	--primary: 217 33% 17%;
}

.accent-slate.dark {
	--primary: 217.2 32.6% 17.5%;
}

.accent-cocoon {
	--primary: 3 100% 61%;
}

.accent-indigo {
	--primary: 226 52% 46%;
}

.accent-fuchsia {
	--primary: 304 59% 66%;
}

.accent-kaki {
	--primary: 110 12.8 36.9;
}
